import * as yup from 'yup';

let schema = yup.object().shape({
  name: yup.string().required('Укажите имя'),
  email: yup.string().email('Укажите корректный Email').required('Укажите Email'),
  text: yup.string().required('Укажите текст вопроса'),
});


document.addEventListener("DOMContentLoaded", () => {
  document.getElementById("year").innerText = "" + new Date().getFullYear();
});

function toggleMenu() {
  const navClassList = document.getElementById("nav").classList;
  const mobileClosedFlag = "nav-mobile-closed";

  if (navClassList.contains(mobileClosedFlag)) {
    navClassList.remove(mobileClosedFlag);
  } else {
    navClassList.add(mobileClosedFlag);
  }
}

// для "бутерброда" пропишем функцию открытия меню
document.getElementById("burger").addEventListener("click", () => {
  toggleMenu();
});

// для каждого пункта меню пропишем функцию закрытия меню
var elements = document.getElementsByClassName("nav__link");
for (var i = 0; i < elements.length; i++) {
  elements[i].addEventListener("click", function (e) {
    e.preventDefault();

    toggleMenu();
    document.querySelector(this.getAttribute("href")).scrollIntoView({
      behavior: "smooth",
    });
  });
}

window.showDEtails = function showDEtails(id) {
  const hideClass = "servicesV2__hideDetails";

  var serviceDetails = document.getElementsByClassName("servicesV2__details");
  for (var i = 0; i < serviceDetails.length; i++) {
    if ((!serviceDetails[i].classList.contains(hideClass)) && (serviceDetails[i]?.id !==id ))
      serviceDetails[i].classList.add(hideClass);
  }

  const navClassList = document.getElementById(id)?.classList;

  if (navClassList) {
    if (navClassList.contains(hideClass)) {
      navClassList.remove(hideClass);
    } else {
      navClassList.add(hideClass);
    }
  }
}

function clearInputsAndChangeButton() { 
document.getElementById("name").value = "";
document.getElementById("email").value = "";
document.getElementById("text").value = "";
document.getElementById("sendButton").value = "Ваш вопрос отправлен дежурному врачу в Анталию";
};

async function sendFeedbackToServer(data) {

  const url = 'https://'+document.location.host+'/api.php?logger=1';

  try {
    const response = await fetch(url, {
      method: "POST", // или 'PUT'
      body: JSON.stringify(data), // данные могут быть 'строкой' или {объектом}!
      headers: {
        "Content-Type": "application/json",
      },
    });  
    clearInputsAndChangeButton();
  } catch (error) {
    // тут надо что-то придумать,как сообщать клиенту, что его сообщение не отправлено
    console.error("Ошибка:", error);
  }
}

function addErrorToFeedback(elem) {
  const navClassList = document.getElementById(elem).classList;
  const mobileClosedFlag = "errorFeed";
  navClassList.add(mobileClosedFlag);
}

function removeErrorFromFeedback(elem) {
  const navClassList = document.getElementById(elem).classList;
  const mobileClosedFlag = "errorFeed";
  navClassList.remove(mobileClosedFlag);
}


window.feedBack = function feedBack() {
  var feed = {
    name: "",
    email: "",
    text: "",
  };
  feed.name = document.getElementById("name").value;
  feed.email = document.getElementById("email").value;
  feed.text = document.getElementById("text").value;
 

  schema.validate(feed, {
      // Throw on the first error or collect and return all
      abortEarly: false,
    })
    .then(function (value) {
      console.log('then');
      removeErrorFromFeedback("name");
      removeErrorFromFeedback("email");
      removeErrorFromFeedback("text");
      sendFeedbackToServer(value);
    })
    .catch(function (err) {
      console.log('catch');
    removeErrorFromFeedback("name");
    removeErrorFromFeedback("email");
    removeErrorFromFeedback("text");
    console.log('Error=',err);
    if (err?.inner) {
    err?.inner.forEach(e => {
      addErrorToFeedback(e.path);
    });
    };
  });

}
